








import { Component, Vue } from "vue-property-decorator";
import General from "@/components/Actividades/General.vue";

@Component({
  components: {
    General
  },
  meta: {
    // sets document title
    title: "Actividades - Municipalidad de Quilpué",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Revisa aquí las últimas actividades de la comuna."
      },
      keywords: { name: "keywords", content: "Quilpué" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class GeneralView extends Vue {
  private mounted() {
    document.title = "Actividades - Municipalidad de Quilpué";
  }
}
